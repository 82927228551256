<template>
  <div>
      <v-card>
        <v-card-title>
          <span class="headline">Kurse importieren</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p>CSV Datei hier hochladen:</p>
              <v-file-input
              accept=".csv"
              v-model="files"
              label="CSV Kursdaten"
              @change="parseInputFile()"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p>Vorschau der Daten aus der hochgeladenen CSV-Datei. Wenn die Daten korrekt sind können die Kurse
                eingelesen werden. Nach dem Einlesen der Kurse muss der Vorgang nochmals bestätigt werden, damit die
                Kurse importiert werden.</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                v-if="showPreviewTable"
                :headers="previewTable.headers"
                :items="previewTable.data"
                :items-per-page="15"
                class="elevation-1"
              ></v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="showPreviewTable">
            <v-col>
              <v-btn :loading="loading" color="primary" @click="transferEventData()">Kurse einlesen</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="eventList.length > 0">
            <v-col class="mt-6">
              <h3>Vorschau der Kurse</h3>
              <p>Die Kurse wurden erfolgreich eingelesen. Nachfolgend werden die Kurse angezeigt, wie sie imortiert werden.<br>
                Mit einem Klick auf 'Kurse importieren' werden alle Kurse wie angezeigt importiert.</p>
            </v-col>
          </v-row>
          <v-row v-if="eventList.length > 0">
            <v-col>
              <v-data-table
                :items="eventList"
                :headers="eventTable.headers"
                :items-per-page="15"
                :search="search"
                class="elevation-1 mt-6">
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Kurse durchsuchen"
                    class="mx-4 mt-2"
                  ></v-text-field>
                </template>
                <template v-slot:item="props" v-slot:body.append>
                  <tr>
                    <td>{{ props.item.title }}</td>
                    <td nowrap="true">{{ props.item.operatorReference }}</td>
                    <td nowrap="true">{{ locationFromId(props.item.locationId) }}</td>
                    <td nowrap="true">{{ DateToLocaleDE(props.item.startDate) }}</td>
                    <td nowrap="true">{{ DateToLocaleDE(props.item.endDate) }}</td>
                    <td nowrap="true" v-if="props.item.courseLevel || props.item.courseLevel === 0">{{ courseLevelStr(props.item.courseLevel) }}<span v-if="props.item.subLevel">{{subLevelStr(props.item.subLevel)}}</span></td>
                    <td v-else></td>
                    <td nowrap="true">{{ props.item.multiCourseLink ? 'Ja' : 'Nein'}}</td>
                    <td nowrap="true">{{ props.item.active ? 'Ja' : 'Nein'}}</td>
                    <td nowrap=""><span v-for="w in props.item.EventWindows" :key="w.weekday">{{ w.name }} ({{ w.timeStart }}-{{ w.timeEnd }}) </span></td>
                    <td nowrap="true">CHF {{ props.item.totalCosts }}</td>
                    <td nowrap="true">{{ props.item.lessonCount }}</td>
                    <td nowrap="true">{{ props.item.lessonsPerWeek }}</td>
                    <td nowrap="true"><v-chip v-for="e in props.item.EventCategories" :key="e.id" label small>{{ e.name }}</v-chip></td>
                    <td nowrap="true"><a :href="props.item.externalUrl" target="_blank">{{ props.item.externalUrl }}</a></td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="eventList.length > 0">
            <v-col>
              <p class="mt-3">Sollen die bestehenden Kurse ersetzt werden?</p>
              <v-select v-model="importType" :items="importTypes" item-text="label" item-value="value"></v-select>
            </v-col>
          </v-row>
          <v-row v-if="eventList.length > 0 && importType === 0">
            <v-col>
              <v-alert type="warning"><strong>Achtung!</strong> Mit dem importieren der Kurse werden alle bereits vorhandenen Kurse entfernt und durch die neu importierten Kurse ersetzt.</v-alert>
            </v-col>
          </v-row>
          <v-row v-if="eventList.length > 0">
            <v-col>
              <v-btn @click="dialog.confirmImport = true" large color="primary" :loading="importProcessing">Angezeigte Kurse importieren!</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-alert type="success" v-model="alert.success.state">Die Kurse wurden erfolgreich importiert! Sie können dieses Fenster schliessen.</v-alert>
              <v-alert type="error" v-model="alert.error.state">Die Kurse konnten nicht importiert werden. Achten Sie auf eine korrekte Formatierung des CSV. Fehlermeldung: {{ alert.error.message }}</v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()"
          >
            Schliessen
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog.confirmImport" max-width="400">
        <v-card>
          <v-card-title class="text-h6">Kurse importieren?</v-card-title>
          <v-card-text>
            <p v-if="importType === 0">Es werden {{ eventList.length }} Kurse importiert. Alle bestehenden Kurse werden engültig entfernt und durch die importierten Kurse ersetzt. Fortfahren?</p>
            <p v-else>Es werden {{ eventList.length }} Kurse importiert. Die bestehenden Kurse werden nicht entfernt, die importierten Kurse werden angefügt. Fortfahren?</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog.confirmImport = false">Abbrechen</v-btn>
            <v-btn color="primary" text @click="importEvents()">Kurse Importieren</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)
import { showSnack } from '../globalActions'
// eslint-disable-next-line camelcase
import { required, email, regex, required_if, confirmed } from 'vee-validate/dist/rules'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import moment from "moment";

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'Dies ist ein Pflichtfeld'
})

extend('email', {
  ...email,
  message: 'Es muss eine Email Adresse eingegeben werden'
})

extend('regex', {
  ...regex,
  message: 'Die Eingabe entspricht nicht dem erwarteten Format'
})

extend('confirmed', {
  ...regex,
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

extend('password', {
  params: ['target'],
  validate (value, { target }) {
    return value === target
  },
  message: 'Das eingegebene Passwort stimmt nicht überein'
})

export default {
  name: 'CsvImport',
  props: ['operatorId'],
  components: {
  },
  data: function () {
    return {
      eventDialogVisible: false,
      dialog: {
        confirmImport: false
      },
      alert: {
        success: {
          state: false
        },
        error: {
          message: '',
          state: false
        }
      },
      eventWindows: {
        mo: {
          enabled: false,
          weekday: 1,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Montag',
          menu: false,
          menuEnd: false
        },
        tu: {
          enabled: false,
          weekday: 2,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Dienstag',
          menu: false,
          menuEnd: false
        },
        we: {
          enabled: false,
          weekday: 3,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Mittwoch',
          menu: false,
          menuEnd: false
        },
        th: {
          enabled: false,
          weekday: 4,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Donnerstag',
          menu: false,
          menuEnd: false
        },
        fr: {
          enabled: false,
          weekday: 5,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Freitag',
          menu: false,
          menuEnd: false
        },
        sa: {
          enabled: false,
          weekday: 6,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Samstag',
          menu: false,
          menuEnd: false
        },
        su: {
          enabled: false,
          weekday: 7,
          startTime: '00:00',
          endTime: '00:00',
          label: 'Sonntag',
          menu: false,
          menuEnd: false
        }
      },
      menu: {
        startDate: false,
        endDate: false
      },
      search: '',
      files: null,
      importType: 0,
      importTypes: [
        {
          value: 0,
          label: 'Kurse ersetzen'
        },
        {
          value: 1,
          label: 'Kurse anfügen'
        }
      ],
      eventTable: {
        headers: [
          {text: 'Kurstitel', align: 'start', value: 'title'},
          {text: 'Referenz / Nr.', value: 'operatorReference'},
          {text: 'Kursort', value: 'EventLocation'},
          {text: 'Kursstart', value: 'startDate'},
          {text: 'Kursende', value: 'endDate'},
          {text: 'Niveau', value: 'courseLevel'},
          {text: 'Kurssammlung', value: 'multiCourseLink'},
          {text: 'Aktiv', value: 'active'},
          {text: 'Zeiten', value: 'windows'},
          {text: 'Gesamtkosten', value: 'totalCosts'},
          {text: 'Anzahl Lektionen', value: 'lessonCount'},
          {text: 'Lektionen pro Woche', value: 'lessonsPerWeek'},
          {text: 'Tags', value: 'tags'},
          {text: 'URL', value: 'externalUrl'}
        ]
      },
      courseLevels: [
        {value: 0, text: 'A0'},
        {value: 1, text: 'A1'},
        {value: 2, text: 'A2'},
        {value: 3, text: 'B1'},
        {value: 4, text: 'B2'},
        {value: 5, text: 'C1'},
        {value: 6, text: 'C2'}
      ],
      loading: false,
      locationList: [],
      categoryList: [],
      showPreviewTable: false,
      importProcessing: false,
      previewTable: {
        headers: [],
        data: []
      },
      eventList: []
    }
  },
  mounted () {
    this.getLocations()
  },
  methods: {
    getLocations () {
      axios
        .get('/operators/' + this.operatorId + '/locations')
        .then((response) => {
          this.locationList = response.data
        })
    },
    getCategoryList () {
      axios
        .get('/operators/' + this.operatorId + '/categories')
        .then((response) => {
          this.categoryList = response.data
        })
    },
    parseInputFile () {
      this.loading = true
      this.$papa.parse(this.files, {
        header: true,
        skipEmptyLines: true,
        encoding: 'utf8',
        transformHeader: function (h) {
          return h.toLowerCase()
        },
        complete: (results, file) => {
          this.showPreviewTable = true
          this.previewTable.headers = []
          results.meta.fields.forEach(header => {
            this.previewTable.headers.push({
              text: header,
              value: header
            })
          })
          this.previewTable.data = results.data
          this.loading = false
        }
      })
    },
    transferEventData () {
      this.loading = true
      if (this.previewTable.data && this.previewTable.data.length > 0) {
        axios.post('/operators/' + this.operatorId + '/events/csv/parse', this.previewTable.data)
          .then((response) => {
            if (response.status === 200) {
              showSnack({ message: 'Kurse wurden eingelesen', color: 'success' })
              this.eventList = response.data
            } else {
              showSnack({ message: 'Kurs konnten nicht korrekt eingelesen werden, achten Sie auf eine korrekte Formatierung im csv' })
            }
          })
          .catch((response) => {
            showSnack({ message: 'Kurs konnten nicht korrekt eingelesen werden, achten Sie auf eine korrekte Formatierung im csv' })
          })
          .finally(() => this.loading = false)
      } else {
        showSnack({ message: 'Keine Daten vorhanden, zuerst CSV Datei auswählen' })
      }
    },
    importEvents () {
      this.dialog.confirmImport = false
      this.importProcessing = true
      if (this.eventList && this.eventList.length > 0) {
        axios.post('/operators/' + this.operatorId + '/events/csv/import', { eventList: this.eventList, importType: this.importType })
          .then((response) => {
            if (response.status === 200) {
              this.alert.error.state = false
              this.alert.success.state = true
            } else {
              showSnack({ message: 'Kurs konnten nicht korrekt eingelesen werden, achten Sie auf eine korrekte Formatierung im csv' })
            }
          })
          .catch((response) => {
            showSnack({ message: 'Kurs konnten nicht korrekt eingelesen werden, achten Sie auf eine korrekte Formatierung im csv' })
            this.alert.error.message = response.message
            this.alert.error.state = true
          })
          .finally(this.importProcessing = false)
      } else {
        showSnack({ message: 'Keine Daten vorhanden, zuerst Kurse einlesen' })
      }
    },
    DateToLocaleDE (date) {
      // To have a leading day in Date, use small caps 'dd'. To get them in the right language, use .locale('lang')
      return moment(date).locale('de').format('dd, DD.MM.YYYY')
    },
    courseLevelStr (levels) {
      let strLevel = this.courseLevels[levels[0]].text
      if (levels.length > 1) {
        levels.forEach((l, i) => {
          if (i > 0) strLevel = strLevel + ',' + this.courseLevels[l].text
        })
      }

      return strLevel
    },
    subLevelStr (subLevel) {
      let levelStr = '-' + subLevel.toString()
      if (subLevel === 5) levelStr = '+'

      return levelStr
    },
    closeDialog () {
      this.resetCsvDialog()
      this.$emit('closeDialog')
      this.$emit('getEvents')
    },
    resetCsvDialog () {
      this.eventList = []
      this.previewTable = {
        headers: [],
        data: []
      }
    },
    locationFromId (id) {
      if (this.locationList && this.locationList.length > 0) {
        const index = this.locationList.findIndex(l => l.id === id)
        if (index >= 0) {
          return this.locationList[index].address
        }
      } else {
        return null
      }
      return null
    }
  },
  computed: {

  }
}
</script>

<style scoped>

</style>
