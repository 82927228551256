<template>
  <div>
    <h4 class="mt-3">
      Statistiken zu Kursen
    </h4>

    <v-data-table
      :items="eventList"
      :headers="eventTable.headers"
      :items-per-page="10"
      :search="search"
      :loading="loading"
      sort-by="clickCount"
      :sort-desc="true"
      class="elevation-1 mt-6">
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Kurse durchsuchen"
          class="mx-4 pt-6"
        ></v-text-field>
      </template>
      <template v-slot:item="props" v-slot:body.append>
        <tr>
          <td nowrap="true">{{ props.item.title }}</td>
          <td nowrap="true">{{ props.item.EventLocation.Operator.name }}</td>
          <td nowrap="true">{{ props.item.operatorReference }}</td>
          <td nowrap="true">{{ props.item.active ? "Ja" : "Nein" }}</td>
          <td nowrap="true">{{ props.item.clickCount ? props.item.clickCount : '-' }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from 'axios'
import {showSnack} from '../globalActions'
import moment from 'moment'
import EventDialog from './EventDialog'
import CsvImport from './CsvImport'

export default {
  name: 'EventStats',
  components: {
  },
  props: ['operatorId'],
  data () {
    return {
      eventActions: {

      },
      loading: false,
      operator: {
        id: this.operatorId
      },
      eventItemId: null,
      context: {
        show: false,
        x: 0,
        y: 0,
        item: null
      },
      search: '',
      eventTable: {
        headers: [
          {text: 'Kurstitel', align: 'start', value: 'title', filterable: true },
          {text: 'Anbieter', align: 'start', value: 'EventLocation.Operator.name', filterable: true},
          {text: 'Referenz / Nr.', value: 'operatorReference'},
          {text: 'Aktiv', value: 'active'},
          {text: 'Anzahl Clicks', value: 'clickCount'}
        ]
      },
      eventList: [],
    }
  },
  created () {
  },
  mounted () {
    this.getEvents()
  },
  watch: {
    operatorId: function () {
      this.getEvents()
    }
  },
  methods: {
    getEvents () {
      this.loading = true
      axios
        .get('/management/events')
        .then((response) => {
          this.eventList = response.data

          this.eventList.forEach((e) => {
            if (e.stats) {
              //console.log('stats: ', e.stats, typeof e.stats)
              e.stats = JSON.parse(e.stats)
              e.clickCount = e.stats.clickAmount
            }
          })
        }).finally(() => this.loading = false)
    },
    DateToLocaleDE (date) {
      // To have a leading day in Date, use small caps 'dd'. To get them in the right language, use .locale('lang')
      return moment(date).locale('de').format('dd, DD.MM.YYYY')
    },
    shortDayStr (weekday) {
      return ['', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekday]
    },
    generateUrl (url) {
      if (typeof url === 'string') {
        if (url.indexOf('http') < 0) {
          url = 'https://' + url
        }
        return url
      } else {
        return null
      }
    }
  }
}
</script>

<style scoped>
  .active-checkbox {
    cursor: pointer;
  }
</style>
