<template>
  <div>
    <v-btn @click="addUser" color="primary" class="">Benutzer hinzufügen</v-btn>

    <v-data-table
      :items="userList"
      :items-per-page="10"
      :loading="loading"
      class="elevation-1 mt-6">
      <template v-slot:header="">
        <thead class="v-data-table-header">
        <tr>
          <th class="text-start">Name</th>
          <th class="text-start">Email</th>
          <th class="text-start">Rolle</th>
          <th class="text-start">Aktiv</th>
          <th class="text-start">Aktionen</th>
        </tr>

        </thead>
      </template>
      <template v-slot:item="props">
        <tr @contextmenu="rightClick($event, props.item)">
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.username }}</td>
          <td>{{ props.item.role }}</td>
          <td nowrap="true">
            <v-simple-checkbox
              v-model="props.item.active"
              disabled
            ></v-simple-checkbox>
          </td>

          <td nowrap="true">
            <v-icon small @click="editUser(props.item)" class="mr-2">mdi-pencil</v-icon>
            <v-icon small @click="editPassword(props.item)" class="mr-2">mdi-key</v-icon>
            <v-icon small @click="confirmDeleteUser(props.item)" class="mr-2">mdi-delete</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.user"
              persistent
              max-width="960px">
      <user-dialog
        ref="userActionDialog"
        :operatorId="operatorId"
        :newUser="newUser"
        :newEdit="newEdit"
        :userId="userId"
        :urlPrefix="urlPrefix"
        :changePassword="changePassword"
        @closeDialog="dialog.user = false"
        @getUsers="getUsers"
      >
      </user-dialog>
    </v-dialog>
    <v-menu
      v-model="context.show"
      :position-x="context.x"
      :position-y="context.y"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item @click="context.show = false; editUser(context.item)"
        >
          <v-list-item-title >Benutzer bearbeiten</v-list-item-title>
        </v-list-item>
        <v-list-item @click="context.show = false; editPassword(context.item)">
          <v-list-item-title >Passwort zurücksetzen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="context.show = false; confirmDeleteUser(context.item)">
          <v-list-item-title >Benutzer entfernen</v-list-item-title>
        </v-list-item>

      </v-list>
    </v-menu>

    <v-dialog v-model="dialog.confirmDeleteUser" max-width="400">
      <v-card>
        <v-card-title class="text-h6">Diesen Benutzer entfernen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.confirmDeleteUser = false">Abbrechen</v-btn>
          <v-btn color="blue darken-1" :loading="loading" text @click="deleteUser">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { showSnack } from '../globalActions'
import UserDialog from './UserDialog'

export default {
  name: 'UserManagement',
  props: ['operatorId', 'fromAdmin'],
  components: {
    'user-dialog': UserDialog
  },
  data () {
    return {
      dialog: {
        user: false,
        confirmDeleteUser: false
      },
      loading: false,
      context: {
        show: false,
        x: 0,
        y: 0,
        item: null
      },
      changePassword: false,
      userId: null,
      editedUser: null,
      newUser: false,
      userList: [],
      urlPrefix: '',
      newEdit: 0
    }
  },
  watch: {
    operatorId: function (newVal, oldVal) {
      this.getUsers()
    }
  },
  created () {
    if (this.fromAdmin) {
      this.urlPrefix = '/management/'
    }
  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      this.loading = true
      axios
        .get(this.urlPrefix + 'operators/' + this.operatorId + '/users')
        .then((response) => {
          this.userList = response.data
        })
        .finally(() => this.loading = false)
    },
    editUser (user) {
      this.userId = user.id
      this.newEdit++
      this.newUser = false
      this.changePassword = false
      this.dialog.user = true
    },
    rightClick(event, item) {
      event.preventDefault();
      this.context.show = false
      this.context.x = event.clientX
      this.context.y = event.clientY
      this.context.item = item
      this.$nextTick(() => {
        this.context.show = true
      })
    },
    editPassword (user) {
      this.userId = user.id
      this.newUser = false
      this.changePassword = true
      this.dialog.user = true
    },
    addUser () {
      if (!this.operatorId) {
        showSnack({ message: 'Kein Kursanbieter ausgewählt', color: 'error' })
        return
      }
      this.userId = null
      this.newUser = true
      this.dialog.user = true
    },
    confirmDeleteUser (user) {
      this.editedUser = user
      this.dialog.confirmDeleteUser = true
    },
    deleteUser () {
      this.loading = true
      axios.delete('/operators/' + this.operatorId + '/users/' + this.editedUser.id)
        .then((response) => {
          if (response.status === 200) {
            showSnack({ message: 'Benutzer wurde entfernt', color: 'success' })
            this.getUsers()
            this.dialog.confirmDeleteUser = false
          } else {
            showSnack({ message: 'Benutzer konnte nicht entfernt werden' })
            this.dialog.confirmDeleteUser = false
          }
        }).catch((e) => {
          if (e.response.data && e.response.data.message) {
            showSnack({ message: 'Benutzer konnte nicht entfernt werden. ' + e.response.data.message  })
          } else {
            showSnack({ message: 'Benutzer konnte nicht entfernt werden. '})
          }
          this.dialog.confirmDeleteUser = false
      }).finally(() => this.loading = false)
    }
  }
}
</script>
